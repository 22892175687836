import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemPerm';
import Successbox from 'components/Web_User_Interface/1440p_Series/Smarthome/Google_Nest/SuccessBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Smarthome Menu // INSTAR Google Nest",
  "path": "/Web_User_Interface/1440p_Series/Smarthome/Google_Nest/",
  "dateChanged": "2022-05-16",
  "author": "Mike Polinowski",
  "excerpt": "The Google Home app Google Home app helps you set up and control Google Nest or Home speakers and displays, and Chromecast. You can control thousands of compatible lights, cameras, speakers, and more. You can also check your reminders and recent notifications, all from a single app. The Google Home app is available on Android phones and tablets, and iPhones and iPads.",
  "image": "./QI_SearchThumb_WebUI_1440p.png",
  "social": "/images/Search/QI_SearchThumb_WebUI_1440p.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SuccessBox = makeShortcode("SuccessBox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Smarthome Menu // INSTAR Google Nest' dateChanged='2022-05-16' author='Mike Polinowski' tag='INSTAR IP Camera' description='The Google Home app Google Home app helps you set up and control Google Nest or Home speakers and displays, and Chromecast. You can control thousands of compatible lights, cameras, speakers, and more. You can also check your reminders and recent notifications, all from a single app. The Google Home app is available on Android phones and tablets, and iPhones and iPads.' image='/images/Search/QI_SearchThumb_WebUI_1440p.png' twitter='/images/Search/QI_SearchThumb_WebUI_1440p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/Smarthome/Google_Nest/' locationFR='/fr/Web_User_Interface/1440p_Series/Smarthome/Google_Nest/' crumbLabel="Alexa" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "smarthome-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#smarthome-menu",
        "aria-label": "smarthome menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Smarthome Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dc9a726313567e4d6de2230710842ecf/3c492/1440p_Settings_Smarthome_Google.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.04347826086956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACtElEQVQ4y6WSS2sbVxiG9SsKxQtbl5FGIxmSWpY0ntHcJY0syePRjGxJTk1oSOLm6kAuTiCQ0EJpm7jQTUugESntqg0kkEUgKV00m0AWWWXTH/OUGTuKFMgidPFw+D7OeXjPd06iWCwiSRJSoUA2k2KxrONdGeNeGlO/+Av2hQOcwzXqhTd+Z1FuMvfpJ5SWlymVSszNzZFMJknEMkkin8+TFQRk1WK4s8dw5/qEQcTpPTZPXYvX4ZfXqZktlo6WUFa0mNJShSNHlkgUCoV3wlwOVamxGfpshutsBN6E6brntekFIxynQ0010WoWhl6nWlEOhJEsFmazqIrGYDBkNNpiOBxNGG0dixkMBgRBwEZwgpbtYZsmhm6jqRblsvy+MEdN1eiHffr9kDAM8X0f31/H97r46x6ra2u47Ta7g2Mc3/BxmzqGbsUpy+WVgxlOJ9Q1jTAM8P0evSCg5/sEm1uEu3cZnr7Jt60ue47LrTN9dncadDt6fG1ds6hEwukZ5nI55KqCv95lNFyjvdqk227hui2anYBOx2O02uD2tas8uPcNZ4+b1C0NUzdwTJNqVZ5NKIo5KmUZ267jeXUadR3L0Gk6Jp7rcPKLbcbjn3j58h/ePH3EnfOfYxsqDcvAsQyqlersDKOE1aqCbTZouU0cy6Tttvnh68v8ev9nnjz7m9evXvD6xV/8+/w5T27fwG/q8cNYxuGVpxNGwuVSGUWuoazUsA2Dsye2ubf/FXfvfM/Dxw/5Y/wjD/a/48/f7rN/5RzbbRVZVlBWVJY+W55NKIoiqVSKZDIV/3ohkyafzSBmBTKZTFwLQoZsLouYFxGifjo5cyYRSSLeCqPmwsLChPn5iPlD3q/nZ/ZGJN6KpoX/h4QYi/IfTPixJKRikUJxEalQJC8VEARhQjS3dDr9UfwHAaYKN5bwcgEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dc9a726313567e4d6de2230710842ecf/e4706/1440p_Settings_Smarthome_Google.avif 230w", "/en/static/dc9a726313567e4d6de2230710842ecf/d1af7/1440p_Settings_Smarthome_Google.avif 460w", "/en/static/dc9a726313567e4d6de2230710842ecf/7f308/1440p_Settings_Smarthome_Google.avif 920w", "/en/static/dc9a726313567e4d6de2230710842ecf/a2baf/1440p_Settings_Smarthome_Google.avif 1300w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dc9a726313567e4d6de2230710842ecf/a0b58/1440p_Settings_Smarthome_Google.webp 230w", "/en/static/dc9a726313567e4d6de2230710842ecf/bc10c/1440p_Settings_Smarthome_Google.webp 460w", "/en/static/dc9a726313567e4d6de2230710842ecf/966d8/1440p_Settings_Smarthome_Google.webp 920w", "/en/static/dc9a726313567e4d6de2230710842ecf/063bf/1440p_Settings_Smarthome_Google.webp 1300w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dc9a726313567e4d6de2230710842ecf/81c8e/1440p_Settings_Smarthome_Google.png 230w", "/en/static/dc9a726313567e4d6de2230710842ecf/08a84/1440p_Settings_Smarthome_Google.png 460w", "/en/static/dc9a726313567e4d6de2230710842ecf/c0255/1440p_Settings_Smarthome_Google.png 920w", "/en/static/dc9a726313567e4d6de2230710842ecf/3c492/1440p_Settings_Smarthome_Google.png 1300w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dc9a726313567e4d6de2230710842ecf/c0255/1440p_Settings_Smarthome_Google.png",
              "alt": "Web User Interface - 1440p Series - Smarthome Google Nest",
              "title": "Web User Interface - 1440p Series - Smarthome Google Nest",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      